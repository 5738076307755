// Import fonts

/* sass-lint:disable max-line-length no-duplicate-properties */
// @font-face {
//   font-family: 'Myfont';
//   src: url('./../fonts/myfont.eot'); /* IE9 Compat Modes */
//   src: url('./../fonts/myfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('./../fonts/myfont.woff2') format('woff2'), /* Modern Browsers */
//   url('./../fonts/myfont.woff') format('woff'), /* Modern Browsers */
//   url('./../fonts/myfont.ttf') format('truetype'), /* Safari, Android, iOS */
//   url('./../fonts/myfont.svg') format('svg'); /* Legacy iOS */
//   font-style: normal;
//   font-weight: 400;
// }
/* sass-lint:enable max-line-length no-duplicate-properties */

@font-face {
  font-family: '8bit';
  src: url('./../fonts/web/8bit.woff2') format('woff2'),
       url('./../fonts/web/8bit.woff') format('woff'),
       url('./../fonts/web/8bit.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

html {
  font-size: calc(1em * .625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  font: 1.4rem / 1.5 '8bit', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
