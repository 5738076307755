html,
body {
  background: $black;
}

body {
  opacity: 0;
  transition: opacity .3s;

  &.ready {
    opacity: 1;
  }
}


.main {
  @include get-all-space;
  position: fixed;
  background: $black;
}

.game-div {
  @include get-all-space;
  position: fixed;
  opacity: 0;
  z-index: 10;
}

.game-container {
  @include center-xy;
  z-index: 10;

  canvas {
    width: 100%;
    height: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
  }
}

.game-container .line {
  position: absolute;
  display: none;
  background: $white;

  &.top {
    top: -.9rem;
    width: 100%;
    height: 1rem;
  }

  &.right {
    right: -.9rem;
    width: 1rem;
    height: 100%;
  }

  &.bottom {
    bottom: -.9rem;
    width: 100%;
    height: 1rem;
  }

  &.left {
    left: -.9rem;
    width: 1rem;
    height: 100%;
  }
}


// .loader {
//   @include get-all-space;
//   z-index: 10;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // transform: scale(1.01);
//   transform-origin: center center;

//   .loader__bar {
//     position: relative;
//     width: 12rem;
//     height: .4rem;
//     background: $white;

//     @include mq(m) {
//       width: 20rem;
//     }
//   }

//   .loader__progress {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 0%;
//     height: 100%;
//     background: #415b00;
//     will-change: width;
//     // transition: width .2s;
//   }
// }

.intro {
  @include get-all-space;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
  color: $white;
  padding: 3rem;
}

.intro__logo {
  width: 25rem;
  opacity: 0;
}

.intro__text {
  line-height: 1.8;
  font-size: 1.4rem;
  margin: 3rem 0;

  br {
    display: none;
  }

  span {
    display: inline-block;
    opacity: 0;
    will-change: transform;
  }

  strong {
    color: #00bc83;
    font-weight: 400;
  }

  @include mq(m) {
    br {
      display: inline-block;
    }
  }
}

.intro__headset {
  opacity: 0;
  width: 5rem;
}

.main {
  @include get-all-space;
  position: fixed;
  background: $black;
}

.background {
  @include get-all-space;
  position: fixed;
  background: $black;
}

.background__image {
  @include get-all-space;
  background-image: url('../images/background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: contain;
}

.background__layer {
  @include get-all-space;
  background: rgba($black, .6);
  opacity: 1;
}

.toggle-audio {
  display: none;
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  z-index: 10;
  font-size: 0;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: filter .4s;

  &.muted {
    filter: grayscale(100%);
    opacity: .6!important;
  }

  img {
    width: 4rem;
  }

  @include mq(l) {
    html.landscape.desktop & {
      display: block;
    }
  }
}

body.game-landscape {
  .background__image {
    background-size: cover;
  }

  .game-container .line {
    display: block;
  }
}
